
































































































































import Vue from "vue";
import { ViewCandidates } from "@/interfaces/recruiter/candidates/view_candidates/view_candidates";
import ViewCandidatesBody from "@/components/recruiter/candidates/view_candidates/ViewCandidatesBody.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  ALL_CANDIDATES,
  FETCH_MATCHING_CANDIDATES,
  GET_FAVORITE_CANDIDATES,
  JOBS_POSTED_BY_RECRUITER,
  MATCHING_CANDIDATES,
  TOP_JOBS_WITH_CANDIDATES,
  VIEW_CANDIDATES_DYNAMICALLY,
  VIEW_CANDIDATES_PAGINATION
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { SearchCandidatesPayload } from "@/store/modules/recruiter/interfaces";
import AutoCompleteUserSearch from "@/components/shared/AutoCompleteUserSearch.vue";
import { SearchJobObject } from "@/interfaces/shared/jobs/auto_complete_job_search";
import { SearchUserObject } from "@/interfaces/shared/auto_complete_user_search";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  /**
   * Activated this page
   *  1: User click on view more button (matching candidates) from dashboard
   *  2: User click on count number from job listing page
   *  3: User click on candidate tab from sidebar
   */
  name: "ViewCandidates",
  components: {
    ViewCandidatesBody,
    AutoCompleteUserSearch
  },
  data(): ViewCandidates {
    return {
      loading: true,
      dynamic_data: false,
      matching_candidates: [],
      job_with_candidate: null,
      advance_search_text: null,
      search_job_id: null,
      job_id: null,
      pagination: 1,
      user_ids: []
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("recruiter", {
      view_candidates_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      get_matching_candidates: FETCH_MATCHING_CANDIDATES,
      top_posted_jobs: TOP_JOBS_WITH_CANDIDATES,
      get_all_candidates: ALL_CANDIDATES,
      view_candidates_pagination: VIEW_CANDIDATES_PAGINATION
    })
  },
  async mounted() {
    await this.init_default_way_data();

    this.loading = false;
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    ...mapActions("recruiter", {
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES,
      fetch_top_jobs_with_candidates: JOBS_POSTED_BY_RECRUITER,
      fetch_matching_candidates_by_id: MATCHING_CANDIDATES,
      fetch_favorite_candidates_by_job: GET_FAVORITE_CANDIDATES
    }),
    ...mapMutations("recruiter", {
      set_candidates_view_dynamically: VIEW_CANDIDATES_DYNAMICALLY,
      set_all_candidates: ALL_CANDIDATES,
      set_view_candidates_pagination: VIEW_CANDIDATES_PAGINATION
    }),

    async init_default_way_data() {
      await this.fetch_candidates_with_pagination(
        this.view_candidates_pagination.pagination
      );
    },
    /**
     * Function to processing search skill filter
     */
    async searched_skill(value: string | null | undefined) {
      // If skill search filter is set & value isn't set yet => return
      if (
        this.view_candidates_pagination.searched_skill_filter &&
        value === undefined
      )
        return;
      // Update view candidate pagination payload
      this.set_view_candidates_pagination({
        pagination: this.view_candidates_pagination.pagination,
        searched_skill_filter: value ?? ""
      });
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value ||
          this.view_candidates_dynamically.job_id ||
          this.view_candidates_dynamically.user_ids?.length
        ),
        loading: false,
        job_id: this.view_candidates_dynamically.job_id,
        job_title: this.view_candidates_dynamically.job_title,
        skill: value ? value : "",
        user_ids: this.view_candidates_dynamically.user_ids || []
      });
      // If skill search filter reset => fetch all candidates (Optionally based on job if set)
      if (!value) {
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * Function to processing search job filter
     */
    async searched_job(value: SearchJobObject) {
      // Update local variable => hold the job id
      this.search_job_id = value ? value.job_id : null;
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value ||
          this.view_candidates_pagination.searched_skill_filter ||
          this.view_candidates_dynamically.user_ids?.length
        ),
        loading: false,
        job_id: value ? value.job_id : null,
        job_title: value ? value.text : "",
        skill: this.view_candidates_pagination.searched_skill_filter,
        user_ids: this.view_candidates_dynamically.user_ids || []
      });
      if (!value) {
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * Function to extract Ids from selected user
     */
    async searched_user(value: SearchUserObject[]) {
      this.user_ids = value?.map((item: SearchUserObject) => item.id);
      // Update data set in order to view candidates
      this.set_candidates_view_dynamically({
        active: !!(
          value?.length ||
          this.view_candidates_pagination.searched_skill_filter ||
          this.view_candidates_dynamically.job_id
        ),
        loading: false,
        job_id: this.view_candidates_dynamically.job_id,
        job_title: this.view_candidates_dynamically.job_title,
        skill: this.view_candidates_pagination.searched_skill_filter,
        user_ids: this.user_ids
      });
      if (this.user_ids?.length === 0) {
        this.set_view_candidates_pagination({
          pagination: 1,
          searched_skill_filter:
            this.view_candidates_pagination.searched_skill_filter
        });
        await this.fetch_candidates_with_pagination(1);
      }
    },
    /**
     * FUnction to fetch top posted job data
     * For by default displaying in job autocomplete field
     */
    fetch_top_job_data(): SearchJobObject | null {
      if (this.top_posted_jobs.results?.length <= 0) return null;
      else if (
        this.view_candidates_dynamically.active &&
        this.view_candidates_dynamically.job_id
      ) {
        return {
          job_id: this.view_candidates_dynamically.job_id,
          text: this.view_candidates_dynamically.job_title
        };
      } else {
        return null;
      }
    },
    reset_data() {
      this.active_tab = "online";
      this.matching_candidates = [];
      this.advance_search_text = null;
      this.search_job_id = null;
      this.job_with_candidate = null;
      this.loading = false;
      this.pagination = 1;
      this.job_id = null;
      this.user_ids = [];
    },
    /**
     * Function to handle filter form submit
     */
    async filter_form_submit() {
      this.loading = true;
      if (!this.user_ids?.length) return;
      this.set_view_candidates_pagination({
        pagination: 1
      });
      await this.fetch_candidates_with_pagination();
      this.loading = false;
    },

    /**
     * Function to fetch candidates with pagination
     */
    async fetch_candidates_with_pagination(page = 1, limit = 12) {
      this.loading = true;
      const payload: SearchCandidatesPayload = {
        page: page - 1,
        limit_per_page: limit,
        n: 20,
        user_ids: this.user_ids?.length > 0 ? this.user_ids : [],
        registered: true,
        approved: true
      };

      // Fetching all candidates
      const _all_candidates = await this.fetch_matching_candidates(payload);
      let all_candidates = _all_candidates;

      // If candidates found
      if (
        (all_candidates &&
          all_candidates.results &&
          all_candidates.results?.length > 0) ||
        all_candidates?.length > 0
      ) {
        // Set filter count to -1 => if not set already
        if (!all_candidates.filtered_count) all_candidates.filtered_count = -1;
        this.set_all_candidates(all_candidates); // Setting in store

        this.matching_candidates = this.get_all_candidates.results;
      }
      // If fetching all candidates failed
      else {
        this.root_error("No Candidates Found");
        this.set_all_candidates({ total: 0, results: [], filtered_count: -1 });
        this.reset_data();

        // If users filter active
        if (this.user_ids?.length) {
          const ref = this.$refs.autocomplete_user as InstanceType<
            typeof AutoCompleteUserSearch
          >;
          ref.reset_state();
        }
      }
      this.loading = false;
    }
  }
});
